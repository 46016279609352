import React, { useState, useEffect, useRef, useContext } from 'react';

import {
	useParams
} from "react-router-dom";

import logo from '../css/logo.svg';
import deluxepcs from '../css/deluxepcs.png';

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';

//Icons
import IconButton from '@mui/material/IconButton';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const styles = {
	basictable: {
		//wordWrap:"break-word",
		tableLayout: "auto",
		border: "2px solid #D4D4D4",
		borderCollapse: "collapse",
		borderSpacing: "0",
		"& > thead > tr > td": {
			border: "1px solid #F9F9F9",
			borderSpacing: "0px",
			padding: "3px"
		},
		"& > tfoot > tr > td": {
			border: "1px solid #F9F9F9",
			borderSpacing: "0px",
			padding: "3px"
		},
		"& > tr > td": {
			border: "1px solid #F9F9F9",
			borderSpacing: "0px",
			padding: "3px"
		},
		/*
		"@media print":{
			breakInside: "avoid",
			pageBreakInside:"avoid",
			"page-break-inside":"avoid"
		}*/
	},

	basictableheader: {
		padding: "6px",
		borderBottom: "2px solid #D4D4D4",
		backgroundColor: "#EAEAEA",
		fontWeight: "bold"
	}
}

const tablestyle = {
	width: "100%",
	tableLayout: "auto",
	border: "2px solid #D4D4D4",
	borderCollapse: "collapse",
	borderSpacing: "0",
	"& > thead > tr > td": {
		border: "1px solid #F9F9F9",
		borderSpacing: "0px",
		padding: "3px"
	},
	"& > tfoot > tr > td": {
		border: "1px solid #F9F9F9",
		borderSpacing: "0px",
		padding: "3px"
	},
	"& tbody > tr > td": {
		border: "2px solid #000",
		borderSpacing: "0px",
		padding: "3px"
	},
	"& > tr > td": {
		border: "2px solid #000",
		borderSpacing: "0px",
		padding: "3px"
	},
	"& tbody > tr": {
		border: "2px solid #000",
		borderSpacing: "0px",
		padding: "3px"
	}
}

const tableheaderstyle = {
	padding: "6px",
	borderBottom: "2px solid #D4D4D4",
	borderTop: "2px solid #D4D4D4",
	backgroundColor: "#EAEAEA",
	fontWeight: "bold",
	textAlign: "left"
}


function EmailSettings() {

	const showform = true;
	let givenurl = "";
	let { urldomain } = useParams();
	console.log(urldomain);
	if (urldomain) {
		givenurl = urldomain;
	}

	const [state, UpdateState] = useState({
		domain: urldomain,
		matchfound: false,
		domainlower: "",
		showform: showform,
		init: false,
		showerror: false,
		errormessage: ""

	});

	let localstate = Object.assign({}, state);



	const [domain, setDomain] = useState("");
	const SetDomain = (event) => {
		localstate.domain = event.target.value;
		UpdateState(localstate);
	}

	const handleKeyUp = (event) => {
		console.log(event.key);
		localstate.domain = event.target.value;
		if (event.key === "Enter") {
			CheckDomain(localstate);
		}
	}

	const CheckDomain = (localstate) => {
		//Check Domain!
		//To Lower
		const domainlower = localstate.domain.toLowerCase();

		if (domainlower === "cpe-labs.com" ||
			domainlower === "vogtwarehouse.com" ||
			domainlower === "simbaproducts.com" ||
			domainlower === "ivac.biz" ||
			domainlower === "wigginscleaning.com"
		) {
			localstate.domain = domainlower;
			localstate.domainlower = domainlower;
			localstate.matchfound = true;
			localstate.showerror = false;
			localstate.showform = false;
			console.log("MATCH FOUND!");
			UpdateState(localstate);
		} else {
			localstate.matchfound = false;
			localstate.showerror = true;
			localstate.errormessage = "No settings found for domain: " + domainlower;
			UpdateState(localstate);
		}

	}

	const CopyValue = (value) => {
		navigator.clipboard.writeText(value);
	}




	useEffect(() => {
		if (!localstate.init && urldomain !== undefined) {
			console.log('No init and urldomain is not empty!');
			localstate.init = true;
			CheckDomain(localstate);
		}
	}, []);




	return (
		<>
			<header className="App-header">
				<b>Email Settings</b>
				<div style={{ fontSize: "16px" }}>
					<a href="/">Go Back to Home Page</a>
				</div>

			</header>


			<Box sx={{ flexGrow: 1 }}>
				<Grid container spacing={2} alignItems="stretch" direction="row"
					justifyContent="center">
					<Grid item xs={12} sm={12}>
						<Card sx={{ minWidth: 900, height: "100%" }}>
							<CardContent>
								<Typography sx={{ fontSize: 30 }} color="text.secondary" gutterBottom>
									Email Configuration
								</Typography>
								<Typography variant="h5" component="div">
									{(localstate.showform) &&
										<div style={{ fontSize: "24px", fontWeight: "bold", padding: "20px", textAlign: "center" }}>
											<div style={{ fontSize: "18px", fontWeight: "normal" }}>
												Fill in the email domain and click "Check for Settings". If your domain is found, your email settings will appear.<br></br><br></br>
											</div>


											<div style={{ display: "inline-block", paddingBottom: "12px", verticalAlign: "bottom" }}>Domain:&nbsp;</div>
											<TextField onKeyUp={(event) => { handleKeyUp(event) }} onChange={(event) => SetDomain(event)} id="outlined-basic" label="yourcompany.com" variant="outlined" InputProps={{
												style: {
													fontSize: "20px",
													padding: 0,
													margin: "0px"
												},
												input: {
													style: {
														padding: "0px",
														margin: "0px"
													}
												}
											}} />
											<div style={{ display: "inline-block", paddingLeft: "10px" }}>
												<Button variant="contained" disableElevation onClick={() => { CheckDomain(localstate) }} style={{ fontSize: "28px" }}>Check For Settings</Button>
											</div>
										</div>
									}

									{(localstate.showerror) &&
										<div style={{ color: "red", textAlign: "center" }}>{localstate.errormessage}</div>
									}

									{(!localstate.showform) &&
										<div>
											<table className="tablestyle">
												<tbody>
													<tr className="tableheaderstyle">
														<td colSpan="2" style={{paddingLeft:"8px"}}>Email Settings for {localstate.domain}</td>
													</tr>


													<tr>
														<td style={{ textAlign: "right", width: "290px" }}>
															Webmail:
														</td>
														<td style={{ textAlign: "left" }}>
															<a href={"https://" + localstate.domain + "/roundcube"} target="_blank">https://{localstate.domain}/roundcube</a>
														</td>
													</tr>
													<tr>
														<td style={{ textAlign: "right", verticalAlign:"top" }}>
															Login Username\Email:
														</td>
														<td style={{ textAlign: "left" }}>
															exampleuser@{localstate.domain}
															<div style={{fontSize:"16px"}}>
																Some email programs may attempt to remove the domain from your login username when you are in the process of setting up your account on a device. The server is particular about having the domain attached. You may need to re-add your domain to the login username: exampleuser@yourcompany.com
															</div>
														</td>
													</tr>
												



													<tr className="tableheaderstyle">
														<td colSpan="2" style={{paddingLeft:"8px"}}>IMAP Settings</td>
													</tr>
													<tr>
														<td style={{ textAlign: "right" }}>
															IMAP Server Hostname:
														</td>
														<td style={{ textAlign: "left" }}>
															<div style={{display:"inline-block"}}>
																mail.{localstate.domain}
																<IconButton aria-label="delete" style={{display:"relative", float:"right"}}>
																<ContentCopyIcon style={{ fontSize: "16px", "&:hover": { cursor: "pointer" } }} onClick={() => { CopyValue("mail." + localstate.domain) }} />
															</IconButton>
															</div>
														</td>
													</tr>
													<tr>
														<td style={{ textAlign: "right" }}>
															IMAP Server Port:
														</td>
														<td style={{ textAlign: "left" }}>
															<div style={{display:"inline-block"}}>
																993
															</div>
														</td>
													</tr>
													<tr>
														<td style={{ textAlign: "right" }}>
															Use SSL for IMAP:
														</td>
														<td style={{ textAlign: "left" }}>
															<div style={{display:"inline-block"}}>
																Yes
															</div>
														</td>
													</tr>
													<tr>
														<td style={{ textAlign: "right" }}>
														IMAP encryption type:	
														</td>
														<td style={{ textAlign: "left" }}>
															<div style={{display:"inline-block"}}>
																STARTTLS
															</div>
														</td>
													</tr>
													<tr>
														<td style={{ textAlign: "right" }}>
														 Password encryption type:
														</td>
														<td style={{ textAlign: "left", verticalAlign:"top" }}>
															<div style={{display:"inline-block"}}>
																Plain
															</div>
														</td>
													</tr>




													<tr className="tableheaderstyle">
														<td colSpan="2" style={{paddingLeft:"8px"}}>POP Settings
														</td>
													</tr>
													<tr>
														<td style={{ textAlign: "right" }}>
															POP3 Server Hostname:
														</td>
														<td style={{ textAlign: "left" }}>
															<div style={{display:"inline-block"}}>
																mail.{localstate.domain}
																<IconButton aria-label="delete" style={{display:"relative", float:"right"}}>
																<ContentCopyIcon style={{ fontSize: "16px", "&:hover": { cursor: "pointer" } }} onClick={() => { CopyValue("mail." + localstate.domain) }} />
															</IconButton>
															</div>
														</td>
													</tr>
													<tr>
														<td style={{ textAlign: "right" }}>
															POP3 Server Port:
														</td>
														<td style={{ textAlign: "left" }}>
															<div style={{display:"inline-block"}}>
																995
															</div>
														</td>
													</tr>


													<tr className="tableheaderstyle">
														<td colSpan="2" style={{paddingLeft:"8px"}}>SMTP Settings
														</td>
													</tr>
													<tr>
														<td style={{ textAlign: "right" }}>
															SMTP Server Hostname:
														</td>
														<td style={{ textAlign: "left" }}>
															<div style={{display:"inline-block"}}>
																mail.{localstate.domain}
																<IconButton aria-label="delete" style={{display:"relative", float:"right"}}>
																<ContentCopyIcon style={{ fontSize: "16px", "&:hover": { cursor: "pointer" } }} onClick={() => { CopyValue("mail." + localstate.domain) }} />
															</IconButton>
															</div>
														</td>
													</tr>
													<tr>
														<td style={{ textAlign: "right" }}>
															SMTP Server Port:
														</td>
														<td style={{ textAlign: "left" }}>
															<div style={{display:"inline-block"}}>
																587
															</div>
														</td>
													</tr>
													<tr>
														<td style={{ textAlign: "right" }}>
															Use SSL for SMTP:
														</td>
														<td style={{ textAlign: "left" }}>
															<div style={{display:"inline-block"}}>
																Yes
															</div>
														</td>
													</tr>
													<tr>
														<td style={{ textAlign: "right" }}>
														 SMTP encryption type:
														</td>
														<td style={{ textAlign: "left", verticalAlign:"top" }}>
															<div style={{display:"inline-block"}}>
																STARTTLS
															</div>
														</td>
													</tr>
													<tr>
														<td style={{ textAlign: "right" }}>
														 Password encryption type:
														</td>
														<td style={{ textAlign: "left", verticalAlign:"top" }}>
															<div style={{display:"inline-block"}}>
																Plain
															</div>
														</td>
													</tr>
													
												</tbody>


											</table>
										</div>

									}


								</Typography>
							</CardContent>
						</Card>
					</Grid>
				</Grid>
			</Box>
		</>
	);
}

export default EmailSettings;
