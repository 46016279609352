import React, { useState, useEffect, useRef, useContext } from 'react';

import {
	useParams
} from "react-router-dom";

import logo from '../css/logo.svg';
import deluxepcs from '../css/deluxepcs.png';
import rustdesk1 from '../css/rustdesk1.png';
import rustdesk2 from '../css/rustdesk2.png';

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';

//Icons
import IconButton from '@mui/material/IconButton';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';


function RemoteDesktop() {


	return (
		<>
			<header className="App-header">
				<b>Remote Desktop</b>
				<div style={{ fontSize: "16px" }}>
					<a href="/">Go Back to Home Page</a>
				</div>

			</header>


			<Box sx={{ flexGrow: 1 }}>
				<Grid container spacing={2} alignItems="stretch" direction="row"
					justifyContent="center">
					<Grid item xs={12} sm={12}>
						<Card sx={{ minWidth: 900, height: "100%" }}>
							<CardContent>
								<Typography sx={{ fontSize: 30 }} color="text.secondary" gutterBottom>
									How to setup Remote Desktop with RustDesk
								</Typography>
								<Typography variant="h6" component="div">
									If you need remote assistance for issues with programs or operating system, I may be able to help. If you have contacted me concerning an IT issue, I may refer you to this page and allow you to setup your 
									RustDesk ID and password before we get started. Here is the full description on how to setup RustDesk:
									<br></br>
									<br></br>
									<b>1. Download RustDesk</b><br></br>
									I'll try to keep this link up to date for Windows versions of the program:<br></br>
									Microsoft Windows: <a href="https://github.com/rustdesk/rustdesk/releases/download/1.2.3-2/rustdesk-1.2.3-2-x86_64.exe">rustdesk-1.2.3-2-x86_64.exe</a> updated link 5-7-2024<br></br>
								Other OS distributions can be found on the RustDesk <a href="https://rustdesk.com">website</a>.
									<br></br>
									<br></br>
									<b>2. Install RustDesk</b><br></br>
									When you attempt to open the downloaded program, you may be prompted with various security warnings in your browser or OS. When the program gets started it will immediately give you options on how you want to run RustDesk.
									You can choose to install it (Accept and Install) or run the program without installing it (Run without install). 
									Installation allows for options to run the program as a service and gives your IT guy speedier access if a long term permanent password is set.<br></br>
									<div style={{textAlign:"center", paddingTop:"15px"}}>
										<img src={rustdesk1} />
									</div>
									<br></br>
									<br></br>
									<b>3. Get Your ID and Password</b><br></br>
									Under the heading "You Desktop", there should be an ID and One-time Password. You may call or text these to me and depending on my workload, I be able to help you with your issue in a timely fashion. Please let me know what 
									time ranges work best for you.<br></br>
									In some cases, you may have an issue with your ID stuck on "Generating". I have found that closing the program (along with any instances in the taskbar) and reopening it seems to help. Also restarting the machine is a fool-proof way
									to know you have restarted RustDesk.
									<div style={{textAlign:"center", paddingTop:"15px"}}>
										<img src={rustdesk2} />
									</div>
									<br></br>
									<br></br>
									<b>4. Setup Long Term Support (Optional)</b><br></br>
									If you would like for the machine to be remotely managed on a continuous basis, you can set a permanent password. Simply click the edit icon next to your One-time Password. This will send you to the Security settings.
									Click "Unlock Security Settings" and proceed to the button "Set permanent password". Unlike the One-Time Password which regenerates on every reboot or restart of RustDesk, a permanent password will not change. <b>Do not give the ID and password to
										someone you don't 100% trust with your device.</b>
									<br></br>
									<br></br>
									<b>5. Setup Service (Optional)</b><br></br>
									If you want this program running all of the time for ease of access, you can go to the settings in RustDesk, go to the General settings, and start the service.
								
								</Typography>
							</CardContent>
						</Card>
					</Grid>
				</Grid>
			</Box>
		</>
	);
}

export default RemoteDesktop;
