import React, { useState, useEffect, useRef, useContext } from 'react';

import {
	useParams
} from "react-router-dom";

import logo from '../css/logo.svg';
import deluxepcs from '../css/deluxepcs.png';

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';

//Icons
import IconButton from '@mui/material/IconButton';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const styles = {
	basictable: {
		//wordWrap:"break-word",
		tableLayout: "auto",
		border: "2px solid #D4D4D4",
		borderCollapse: "collapse",
		borderSpacing: "0",
		"& > thead > tr > td": {
			border: "1px solid #F9F9F9",
			borderSpacing: "0px",
			padding: "3px"
		},
		"& > tfoot > tr > td": {
			border: "1px solid #F9F9F9",
			borderSpacing: "0px",
			padding: "3px"
		},
		"& > tr > td": {
			border: "1px solid #F9F9F9",
			borderSpacing: "0px",
			padding: "3px"
		},
		/*
		"@media print":{
			breakInside: "avoid",
			pageBreakInside:"avoid",
			"page-break-inside":"avoid"
		}*/
	},

	basictableheader: {
		padding: "6px",
		borderBottom: "2px solid #D4D4D4",
		backgroundColor: "#EAEAEA",
		fontWeight: "bold"
	}
}

const tablestyle = {
	width: "100%",
	tableLayout: "auto",
	border: "2px solid #D4D4D4",
	borderCollapse: "collapse",
	borderSpacing: "0",
	"& > thead > tr > td": {
		border: "1px solid #F9F9F9",
		borderSpacing: "0px",
		padding: "3px"
	},
	"& > tfoot > tr > td": {
		border: "1px solid #F9F9F9",
		borderSpacing: "0px",
		padding: "3px"
	},
	"& tbody > tr > td": {
		border: "2px solid #000",
		borderSpacing: "0px",
		padding: "3px"
	},
	"& > tr > td": {
		border: "2px solid #000",
		borderSpacing: "0px",
		padding: "3px"
	},
	"& tbody > tr": {
		border: "2px solid #000",
		borderSpacing: "0px",
		padding: "3px"
	}
}

const tableheaderstyle = {
	padding: "6px",
	borderBottom: "2px solid #D4D4D4",
	borderTop: "2px solid #D4D4D4",
	backgroundColor: "#EAEAEA",
	fontWeight: "bold",
	textAlign: "left"
}


function EmailSettings() {

	

	return (
		<>
			<header className="App-header">
				<b>Computer Setup</b>
				<div style={{ fontSize: "16px" }}>
					<a href="/">Go Back to Home Page</a>
				</div>

			</header>


			<Box sx={{ flexGrow: 1 }}>
				<Grid container spacing={2} alignItems="stretch" direction="row"
					justifyContent="center">
					<Grid item xs={12} sm={12}>
						<Card sx={{ minWidth: 900, height: "100%" }}>
							<CardContent>
								<Typography sx={{ fontSize: 30 }} color="text.secondary" gutterBottom>
									Windows Computer Setup
								</Typography>
								<Typography variant="h5" component="div">
                                
                                <a href={process.env.PUBLIC_URL + '/files/Ninite.exe'} download>Ninite Build - 5-17-2024</a><br></br>
                                    <a href="https://winaero.com/winaero-tweaker/">Winaero Tweaker</a><br></br>
                                    <a href={process.env.PUBLIC_URL + '/files/Winaero Tweaker_2024_05_17.ini'} download>Winaero Tweaker Settings File</a><br></br>
                                    <a href={process.env.PUBLIC_URL + '/files/Windhawk.7z'} download>Windhawk - Middle click to close, dark mode notepad, other tweaks.</a><br></br>

                                    <a href={process.env.PUBLIC_URL + '/files/netspeedmonitor_2_5_4_0_x64_setup.msi'} download>Net Speed Monitor (run in compatibility mode)</a>
								</Typography>
							</CardContent>
						</Card>
					</Grid>
				</Grid>
			</Box>
		</>
	);
}

export default EmailSettings;
