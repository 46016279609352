//import logo from '../css/logo.svg';
import logo from '../css/logo.png';
import deluxepcs from '../css/deluxepcs.png';

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';


function MainPage() {
	return (
		<>
			<header className="App-header">
			<div style={{ fontSize: "18px", fontWeight: "normal" }}>
			Good day! If you have been sent here to get IT support, you are in the right place. If you have any suggestions for improvement, please let me know.&nbsp;&nbsp;<br></br>-Martin Horner
											</div>
			</header>


			<Box sx={{ flexGrow: 1 }}>
				<Grid container spacing={2} alignItems="stretch"  direction="row"
  justifyContent="center">
					<Grid item xs={12} sm={6}>
						<Card sx={{ minWidth: 275, height:"100%" }}>
							<CardContent>
								<Typography sx={{ fontSize: 30 }} color="text.secondary" gutterBottom>
									Website Hosting
								</Typography>
								<Typography variant="h5" component="div">
									I provide basic hosting services for a number of businesses. If there is an issue with the server, please contact me directly. I will also be adding a contact form to this page at some point.
								</Typography>
							</CardContent>
						</Card>
					</Grid>
					<Grid item xs={12} sm={6}>
						<Card sx={{ minWidth: 275, height:"100%"  }}>
							<CardContent>
								<Typography sx={{ fontSize: 30 }} color="text.secondary" gutterBottom>
									Email Hosting
								</Typography>
								<Typography variant="h5" component="div">
									For users seeking help with email settings, I have provided a settings page in which you can lookup your domain hosted through this server and retrieve your email settings.<br></br>
									<div style={{textAlign:"center", paddingTop:"10px"}}><a href="/emailsettings">Email Settings Page</a></div>
								</Typography>
							</CardContent>
						</Card>
					</Grid>
					<Grid item xs={12} sm={6}>
						<Card sx={{ minWidth: 275, height:"100%" }}>
							<CardContent>
								<Typography sx={{ fontSize: 30 }} color="text.secondary" gutterBottom>
									Desktop and Laptop Computers
								</Typography>
								<Typography variant="h5" component="div">
									I am currently part owner\operator of Deluxe PCs! We sell refurbished business grade laptops and desktops at an aggressive level of service and price. This allows for efficient reuse of IT assets while providing customers with cost effective computer purchases.
									<div style={{textAlign:"center", paddingTop:"20px"}}><a href="https://deluxepcs.com"><img src={deluxepcs} /></a></div>
								</Typography>
							</CardContent>
						</Card>
					</Grid>
					<Grid item xs={12} sm={6}>
						<Card sx={{ minWidth: 275, height:"100%" }}>
							<CardContent>
								<Typography sx={{ fontSize: 30 }} color="text.secondary" gutterBottom>
									Remote Desktop Help
								</Typography>
								<Typography variant="h5" component="div">
								I now use RustDesk for remote desktop assistance. It resembles and functions just like TeamViewer, it's currently free to use, and works on many platforms.


								<div style={{textAlign:"center", paddingTop:"10px"}}><a href="/remotedesktop">Remote Desktop Download and Guide</a></div>
								
								</Typography>
							</CardContent>
						</Card>
					</Grid>
				</Grid>
			</Box>
		</>
	);
}

export default MainPage;
