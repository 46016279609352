
import './css/App.css';
import logo from './css/logo.png';

import {BrowserRouter as Router, Routes, Route, useParams } from 'react-router-dom';

import MainPage from './Components/MainPage';
import EmailSettings from './Components/EmailSettings'; 
import RemoteDesktop from './Components/RemoteDesktop'; 
import ComputerSetup from './Components/ComputerSetup'; 

// const router = createBrowserRouter([
// 	{
// 	  path: "/",
// 	  element: <MainPage />,
// 	},
// 	{
// 		path: "/emailsupport/:domain",
// 		element: <EmailSupport />,
// 	  },
// 	  {
// 		path: "/emailsupport",
// 		element: <EmailSupport />,
// 	  },
//   ]);

function App() {
	return (
		<div className="appbody">
			<div className="appcontainer">
				<div style={{ textAlign: "center" }}><img src={logo} /></div>
				{/* <RouterProvider router={router} />
			 */}
				<Router>
					<Routes>
						<Route path="/" element={<MainPage />} />
						<Route path="/emailsettings">
							<Route path=":urldomain" element={<EmailSettings />} />
							<Route path="" element={<EmailSettings />} />
						</Route>
						<Route path="/remotedesktop" element={<RemoteDesktop />} />
						<Route path="/computersetup" element={<ComputerSetup />} />
					</Routes>
				</Router>




			</div>
		</div>
  );
}

export default App;
